import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { SEO } from '../components'
import BlogPostLayout from "../components/layouts/blog-post"
import HTMLContent from "../components/general/html-content";
import Share from "../components/general/share";
import "../assets/styles/components/blog-page.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const BlogPageTemplate = props => {
  const { page, host } = props;
  const data = page.frontmatter;

  const pageData = {
    title: data.title,
    description: data.subtitle,
    image: data.image,
    date: data.date
  }

  const shareData = {
    host: `https://${host}`,
    url: `https://${host}/${data.path}`,
    title: `OTO Blog - ${data.title}`,
    summary: data.seo.description
  }

  const imageData = getImage(page.frontmatter.image)

  return (
    <main>
      <title>{data.title}</title>
      <BlogPostLayout page={pageData} classes="blog-page">
        <Share data={shareData}/>
        <GatsbyImage image={imageData} className="rounded mb-" alt={data.title}/>
        {/* The page.html is actually markdown when viewing the page from the netlify CMS,
          so we must use the ReactMarkdown component to parse the markdown in that case  */}
        {page.bodyIsMarkdown ? (
          <ReactMarkdown source={page.html} />
        ) : (
          <HTMLContent content={page.html} />
        )}
      </BlogPostLayout>
    </main>
  );
};

const BlogPage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const pathName = isBrowser ? window.location.pathname: ""
  const { markdownRemark: page } = data;
  const host = "heyoto.eu"
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription },
    },
  } = page;

  return (
    <>
      <SEO
        title={seoTitle}
        desc={seoDescription}
        pathname={pathName}
        banner={page.frontmatter.image}
        article={true}
        createTime={page.frontmatter.date}
      />
      <BlogPageTemplate page={{ ...page, bodyIsMarkdown: false }} host={host} />
    </>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const blogPageQuery = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(width: 720, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        date(formatString: "MMMM Do, YYYY")
        path
        seo {
          title
          description
        }
      }
    }
  }
`;