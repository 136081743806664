import React from 'react';
import JSONData from "../data/texts.json"
import WideContentLayout from "../components/layouts/wide-content"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const page = {
    title: "OTO Blog",
    description: "Helping managers rock their 1 on 1s"
  }

  return (
    <main >
      <title>{page.title}</title>
      <WideContentLayout menu={JSONData.menu} page={page}>
        <div className="row">
          {edges.map((edge, i) => {
            const imageData = getImage(edge.node.frontmatter.image)

            return (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4" key={`blog-${i}`}>
                <a href={edge.node.fields.slug} className="features-small-item p-0 pointer">
                  <GatsbyImage image={imageData} className="img-fluid d-block mx-auto mb-2 radius-top-20" alt={edge.node.frontmatter.title}/>
                  <div className="text-left px-4">
                    <h5 className="features-title mb-2">{edge.node.frontmatter.title}</h5>
                    <p className="pb-3">{edge.node.frontmatter.date}</p>
                  </div>
                </a>
              </div>
            )}
          )}
        </div>
      </WideContentLayout>
    </main>
  )
}

export default Blog

export const blogQuery = graphql`
  query BlogIndex {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] },filter: {frontmatter: {template: {eq: "blog-page"}}}) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 350, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;